import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import fetchQuery from "../utils/fetchQuery"
import moment from "moment"
import formatPrice from "../utils/priceFormatter"
import { BOOKING } from "../utils/constants"
import { useEffect } from "react"

const FleetSwiperYacht = ({ y }) => {
    const [yacht, setYacht] = React.useState(y)
    const [isHovered, setIsHovered] = useState(false)
    const [isError, setIsError] = useState(false)

    const getLowestYachtPrice = async (vesselId, date) => {
        await fetchQuery({
            method: "GET",
            endPoint: `configuration/Vessel/GetLowestVesselRentalPriceWithinRequestedMonth?vesselId=${vesselId}&date=${date}`,
            successCallback: lowestPrice => {
                setYacht({
                    ...yacht,
                    lowestPrice
                })
            },
            failCallback: () => {
                setIsError(true)
            }
        })
    }

    useEffect(() => {
        setYacht(y)
    }, [y])

    React.useEffect(() => {
        if (yacht.netId) {
            const date = moment().format("YYYY-MM-DD")
            getLowestYachtPrice(yacht.netId, date)
        }
    }, [yacht.netId])

    return (
        <Link className="text-decoration-none" to={yacht.link}>
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={`card text-start h-100 ${isHovered ? "yacht-selected-card" : ""}`}
            >
                <GatsbyImage
                    onMouseEnter={() => setIsHovered(true)}
                    className="card-img-top"
                    image={getImage(yacht.mainImage)}
                    alt="Yacht image"
                />
                <div className={`card-body ${isHovered ? "yacht-selected-card-body" : ""}`}>
                    <h3 className="card-title mb-3">{yacht.name}</h3>
                    <div className="mb-2">
                        <span className="pre-title">
                            up to {yacht.capacity} + {yacht.type === "main" ? 1 : 3} people
                        </span>
                    </div>
                    {BOOKING && !isError && (
                        <div>
                            <span className="price">From €{formatPrice(yacht?.lowestPrice)}</span>
                        </div>
                    )}
                    <hr />

                    <p className="card-text card-text-fleet">{yacht.description.first.slice(0, 220)}...</p>
                </div>
            </div>
        </Link>
    )
}

export default FleetSwiperYacht
