import * as React from "react"

import { Navigation, Pagination, Scrollbar, A11y } from "swiper"
import FleetSwiperYacht from "./fleetSwiperYacht"
import { Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import Fade from "react-reveal/Fade"
import { ArrowIcon } from "./newsletter"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import { useState } from "react"
import { useEffect } from "react"
import fetchQuery from "../utils/fetchQuery"

const FleetSwiper = ({ yachts }) => {
    const [yachtsData, setYachtsData] = useState(yachts)

    const navigationPrevRef = React.useRef()
    const navigationNextRef = React.useRef()

    const getYachtServerData = async name => {
        await fetchQuery({
            method: "GET",
            endPoint: `configuration/Vessel/GetVesselByName?vesselName=${name}`,
            successCallback: response => {
                setYachtsData(y => {
                    const copyYachts = [...y]
                    const index = copyYachts.findIndex(y => y.name === name)
                    copyYachts[index] = {
                        ...response,
                        ...copyYachts[index],
                        netId: response.id
                    }

                    return copyYachts
                })
            },
            failCallback: () => {}
        })
    }

    useEffect(() => {
        yachts.forEach(y => {
            getYachtServerData(y.name)
        })
    }, [])

    const swiperConfiguration = React.useMemo(
        () => ({
            modules: [Navigation, Pagination, Scrollbar, A11y],
            slidesPerView: 1.1,
            centeredSlides: false,
            spaceBetween: 15,
            autoHeight: true,
            breakpoints: {
                768: {
                    slidesPerView: 2.25,
                    spaceBetween: 25
                },
                992: {
                    slidesPerView: 2.5,
                    spaceBetween: 30,
                    autoHeight: true
                }
            },
            pagination: {
                clickable: true,
                el: ".custom-slider-pagination",
                renderBullet: (index, className) => {
                    return '<span class="' + className + '">' + "</span>"
                }
            },
            onSwiper: swiper => {
                setTimeout(() => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current
                    swiper.params.navigation.nextEl = navigationNextRef.current

                    swiper.navigation.destroy()
                    swiper.navigation.init()
                    swiper.navigation.update()
                })
            }
        }),
        [navigationPrevRef.current, navigationNextRef.current]
    )

    return (
        <div className="container-xxl">
            <div className="row">
                <div className="col-12  ps-0 pe-0  pb-4 pb-md-5 text-center ps-inside pe-inside">
                    <div className="custom-container-xxl gradient-container-fleet">
                        <div className="justify-content-center padding-fleet-swiper-top">
                            <Fade cascade duration={2500}>
                                <div className="mb-3">
                                    <span className="pre-title justify-content-center mt-3">Our family</span>
                                </div>
                                <div>
                                    <h2 className="mb-0">The Fleet</h2>
                                </div>
                            </Fade>
                        </div>

                        <div className="py-3 padding-fleet-swiper-body position-relative">
                            <Swiper {...swiperConfiguration}>
                                {yachtsData
                                    .filter(yac => yac.isActive)
                                    .map(y => (
                                        <SwiperSlide key={y.id}>
                                            <FleetSwiperYacht y={y} />
                                        </SwiperSlide>
                                    ))}
                            </Swiper>
                            <div className="pt-3 custom-slider-pagination d-xl-none" />
                            <div className="custom-swiper-nav d-xl-flex d-none">
                                <button className="custom-prev-nav" ref={navigationPrevRef}>
                                    <ArrowIcon fill="#3D4998" />
                                </button>

                                <button className="custom-next-nav" ref={navigationNextRef}>
                                    <ArrowIcon fill="#3D4998" />
                                </button>
                            </div>
                            <div className="mt-3 mt-xl-5">
                                <Link className="button fill" to="/fleet">
                                    <span>view all yachts</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FleetSwiper
